import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { LicenseManager } from '@ag-grid-enterprise/core';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import '@mantine/core/styles/global.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import './App.theme.css';
import './App.css';
import Home from './pages/Home';
import AuthCallback from './pages/AuthCallback';
import AuthenticationGuard from './components/Authentication/AuthenticationGuard';
import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import LogoLoader from './components/Loading/LogoLoader';
import { WaitingRoom } from './pages/WaitingRoom';
import { useGlobalState } from './data/GlobalState';
import Invoice from './pages/Billing/Invoice';
import Plan from './pages/Billing/Plan';
import Payment from './pages/Billing/Payment';
import AdminFrame from './hoc/Admin/AdminFrame';
import Companies from './pages/Companies';
import { OAuthPopup } from './components/react-use-oauth2';
import { QuickBooksAuth } from './pages/QuickBooksAuth';
import Billing, { BillingFrame } from './pages/Billing';
import CompanyProfile from './pages/CompanyProfile';
import CurrentUsage from './pages/Billing/CurrentUsage';
import SettingsFrame from './hoc/Admin/SettingsFrame';
import CompanyFrame from './hoc/Admin/CompanyFrame';
import ProfileSettings from './pages/Settings/ProfileSettings';
import { useFlag, useUnleashContext } from '@unleash/proxy-client-react';
import { useTokenRefresh } from './components/hooks/useTokenRefresh';
import { datadogRum } from '@datadog/browser-rum';
import { AccountDisabled } from './pages/AccountDisabled';
import ErrorPage from './components/ErrorPage/ErrorPage';
import CompanySettings from './pages/CompanySettings';
import SeasonPricer from './pages/SeasonPricer';
import AutomatorSettings from './pages/AutomatorSettings';
import { auth } from './data/atoms.auth';
import NotificationSettings from './pages/Settings/NotificationSettings';
import AppearanceSettings from './pages/Settings/AppearanceSettings';
import { Mobile, MobileWrapper } from './pages/Mobile';
import { isMobile } from 'react-device-detect';
import CompanyPickerMobile from './pages/CompanyPicker.Mobile';
import { EventListingsMobile } from './pages/EventListings.Mobile';
import { ListingDetailsMobile } from './pages/ListingDetails.Mobile';
import { MarketFiltersMobile } from './pages/MarketFilters.Mobile';
import { Sales } from './pages/Sales';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { Purchase } from './pages/Purchase';
import { isDtiHosted } from './utils/whitelabel-consts';
import RuleIsolation from './pages/RuleIsolation';
import { PricingSettings } from './pages/Settings/PricingSettings';
import { useQueryParam } from './utils/use-query-param';
import { useAuth0 } from '@auth0/auth0-react';
import { Onboarding } from './pages/Onboarding';
import Users from './pages/Users';

ModuleRegistry.registerModules([ExcelExportModule]);

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-057849}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Thirtytech_Inc}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{7_June_2025}____[v3]_[01]_MTc0OTI1MDgwMDAwMA==73ba741f47e5fe068d14defd31f34af3',
);

export function App() {
  const { isLoading, isAuthenticated, user } = useAuth0();
  const setApiToken = useSetAtom(auth.apiTokenAtom);
  const { tenants, principal, selectedTenantIds, isTenantsLoading, isDtiOpu, dtiAccounts } = useGlobalState(
    'tenants',
    'principal',
    'selectedTenantIds',
    'isTenantsLoading',
    'isDtiOpu',
    'dtiAccounts',
  );
  const navigate = useNavigate();
  const location = useLocation();
  const updateUnleashContext = useUnleashContext();
  const { handleAccessTokenSilently } = useTokenRefresh();
  const hideSalesFlag = useFlag('hide-sales');
  const ruleIsolationFlag = useFlag('rule-isolation');
  const hasUsersPageFlag = useFlag('users-page');
  const dti = useQueryParam<'yes' | 'no' | undefined>('dti', undefined);

  const canViewSales = !hideSalesFlag && (dtiAccounts.length === 0 || dtiAccounts.some((a) => a.perms.invoice.enabled === '1'));

  useEffect(() => {
    if (dti) {
      window.localStorage.setItem('use-dti-login', `${dti}`);
      window.location.href = '/';
    }
  }, [dti, navigate]);

  useEffect(() => {
    document.documentElement.setAttribute('data-color-theme', isDtiHosted ? 'dti' : 'bn');
    return () => {
      document.documentElement.removeAttribute('data-color-theme');
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && user?.email) {
      updateUnleashContext({ userId: user?.email });
      datadogRum.setUser({
        id: user?.sub,
        email: user?.email,
      });

      pendo.initialize({
        visitor: {
          id: user?.email ?? 'unknown',
        },
      });

      handleAccessTokenSilently();
    }
  }, [isAuthenticated, setApiToken, updateUnleashContext, handleAccessTokenSilently, user?.email, user?.sub]);

  useEffect(() => {
    if (principal?.isDisabled) {
      navigate('/account-disabled');
      return;
    }

    if (tenants !== undefined && tenants.length === 0 && !location.pathname.includes('qbo')) {
      navigate('/waiting-room');
      return;
    }

    if (tenants !== undefined && tenants.length > 0 && isMobile && !location.pathname.startsWith('/mobile') && !location.pathname.startsWith('/auth')) {
      if (location.search) {
        navigate(`/mobile${location.search}`);
      } else {
        navigate('/mobile');
      }
    }
  }, [tenants, navigate, location.pathname, principal, location.search]);

  if ((isLoading || isTenantsLoading) && isAuthenticated) {
    return <LogoLoader />;
  }

  if (
    isMobile &&
    !isLoading &&
    isAuthenticated &&
    !location.pathname.startsWith('/mobile') &&
    !location.pathname.startsWith('/waiting-room') &&
    !location.pathname.startsWith('/account-disabled') &&
    !location.pathname.startsWith('/auth')
  ) {
    return <LogoLoader />;
  }

  if (isDtiOpu) {
    return (
      <Routes location={location}>
        <Route path="/onboarding" element={<AuthenticationGuard component={Onboarding} />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/waiting-room" element={<AuthenticationGuard component={WaitingRoom} />} />
        {principal?.isDisabled && <Route path="/account-disabled" element={<AuthenticationGuard component={AccountDisabled} />} />}
        <Route
          path="*"
          element={<ErrorPage title="Nothing here" content="You navigated somewhere unexpected. If you believe this to be an error with the pricer, please contact support." />}
        />
        <Route path="/" element={<AuthenticationGuard component={!hideSalesFlag ? Sales : WaitingRoom} />} />
      </Routes>
    );
  }

  return (
    <Routes location={location}>
      <Route path="/onboarding" element={<AuthenticationGuard component={Onboarding} />} />
      {((tenants !== undefined && tenants?.length > 0 && selectedTenantIds.length > 0) || !isAuthenticated) && (
        <>
          <Route path="/" element={<AuthenticationGuard component={Home} />} />
          {ruleIsolationFlag && <Route path="/rule" element={<AuthenticationGuard component={RuleIsolation} />} />}
          <Route path="/seasons" element={<AuthenticationGuard component={SeasonPricer} />} />
          <Route path="/sales" element={<AuthenticationGuard component={canViewSales ? Sales : Home} />} />
          <Route path="/purchase" element={<AuthenticationGuard component={isDtiHosted ? Purchase : Home} />} />
        </>
      )}
      <Route path="/mobile" element={<AuthenticationGuard component={MobileWrapper} />}>
        <Route index Component={Mobile} />
        <Route path="companypicker" Component={CompanyPickerMobile} />
        <Route path="events/:eventId/listings" Component={EventListingsMobile} />
        <Route path="events/:eventId/listings/:listingId/" Component={ListingDetailsMobile} />
        <Route path="events/:eventId/listings/:listingId/filters" Component={MarketFiltersMobile} />
      </Route>
      <Route path="companies/:tenantId?" element={<AuthenticationGuard component={AdminFrame} />}>
        <Route element={<AuthenticationGuard component={CompanyFrame} />}>
          <Route index element={<AuthenticationGuard component={Companies} />} />
          <Route element={<AuthenticationGuard component={BillingFrame} />}>
            <Route path="billing" element={<AuthenticationGuard component={Billing} />} />
            <Route path="billing/invoices/current" element={<AuthenticationGuard component={CurrentUsage} />} />
            <Route path="billing/invoices/:invoiceId" element={<AuthenticationGuard component={Invoice} />} />
            <Route path="billing/plan" element={<AuthenticationGuard component={Plan} />} />
            <Route path="billing/payment" element={<AuthenticationGuard component={Payment} />} />
          </Route>
          <Route path="profile" element={<AuthenticationGuard component={CompanyProfile} />} />
          <Route path="automator" element={<AuthenticationGuard component={AutomatorSettings} />} />
          <Route path="settings/pricing" element={<AuthenticationGuard component={CompanySettings} />} />
          {hasUsersPageFlag && <Route path="users" element={<AuthenticationGuard component={Users} />} />}
        </Route>
      </Route>
      <Route element={<AuthenticationGuard component={AdminFrame} />}>
        <Route element={<AuthenticationGuard component={SettingsFrame} />}>
          <Route path="/settings" element={<Navigate to="/settings/pricing" />} />
          <Route path="/settings/pricing" element={<AuthenticationGuard component={PricingSettings} />} />
          <Route path="/settings/profile" element={<AuthenticationGuard component={ProfileSettings} />} />
          <Route path="/settings/notifications" element={<AuthenticationGuard component={NotificationSettings} />} />
          <Route path="/settings/appearance" element={<AuthenticationGuard component={AppearanceSettings} />} />
        </Route>
      </Route>
      <Route path="/auth/callback" element={<AuthCallback />} />
      <Route path="/qbo/callback" element={<OAuthPopup />} />
      <Route path="/qbo/auth" element={<AuthenticationGuard component={QuickBooksAuth} />} />
      <Route path="/waiting-room" element={<AuthenticationGuard component={WaitingRoom} />} />
      {principal?.isDisabled && <Route path="/account-disabled" element={<AuthenticationGuard component={AccountDisabled} />} />}
      <Route
        path="*"
        element={<ErrorPage title="Nothing here" content="You navigated somewhere unexpected. If you believe this to be an error with the pricer, please contact support." />}
      />
    </Routes>
  );
}
