import { ActionIcon, Box, Center, Container, Group, Modal, Popover, Skeleton, Tabs, Text } from '@mantine/core';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import StadiumIcon from '../../components/icons/Stadium';
import HistoryIcon from '../../components/icons/History';
import {
  BarkerCoreModelsInventoryListingChange,
  BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal,
  useGetApiInventoryListingsListingIdChanges,
  useGetDtiItemsAccountId,
  useGetDtiItemsAccountIdItemIdHistory,
} from '../../api';
import dayjs from 'dayjs';
import { DateFormats } from '../../utils/globals';
import { useGlobalState } from '../../data/GlobalState';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FixedSizeList } from 'react-window';
import { CellProps, TableState, useSortBy, useTable } from 'react-table';
import { formatCurrency, formatDate } from '../../utils/formatters';
import InfoIcon from '../../components/icons/Info';
import classes from './Inventory.styles.tsx.module.css';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import { useDTIInventory } from '../DTI/DTI.Inventory.hooks';
import { useFlag } from '@unleash/proxy-client-react';
import { BarkerEventListing } from '../../types';
import AnalyticsIcon from '../../components/icons/Analytics';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';

function isGUID(str: string) {
  const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guidPattern.test(str);
}

export function ListingHistoryDialogDTI({
  listing,
  isOpen,
  setListing,
}: {
  listing: BarkerEventListing | null;
  isOpen: boolean;
  setListing: React.Dispatch<React.SetStateAction<{ listing: BarkerEventListing | null; isOpen: boolean }>>;
}) {
  const { selectedListing } = useDTIInventory('selectedListing');

  const { data: dtiItems } = useGetDtiItemsAccountId(
    (selectedListing?.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal)?.ownerId,
    {
      listing_id: selectedListing?.listingId,
    },
    {
      query: {
        enabled: !!selectedListing && isOpen,
        select(data) {
          return data.data.items;
        },
      },
    },
  );

  const firstItem = dtiItems?.[0];

  return <ListingHistoryDialog ownerId={firstItem?.owner_id ?? 0} itemId={firstItem?.item_id ?? 0} listing={listing} isOpen={isOpen} setListing={setListing} />;
}

export function ListingHistoryDialog({
  ownerId = 0,
  itemId = 0,
  listing,
  isOpen,
  setListing,
}: {
  ownerId?: number;
  itemId?: number;
  listing: BarkerEventListing | null;
  isOpen: boolean;
  setListing: React.Dispatch<React.SetStateAction<{ listing: BarkerEventListing | null; isOpen: boolean }>>;
}) {
  const { getUserName } = useGlobalState('getUserName');
  const {
    data: history,
    isLoading,
    refetch,
  } = useGetApiInventoryListingsListingIdChanges(listing?.listingId ?? '', {
    query: {
      enabled: !!listing,
    },
    axios: {
      headers: {
        'x-tenant-id': listing?.tenantId,
      },
    },
  });

  const {
    data: dtiHistory,
    isLoading: isDtiLoading,
    refetch: refetchDti,
  } = useGetDtiItemsAccountIdItemIdHistory(ownerId, itemId, {
    query: {
      enabled: !!listing && isDtiHosted && ownerId > 0 && itemId > 0,
    },
  });

  // TODO: Need to fix the loading indicators so they don't have a render frame where nothing is loading before DTI starts loading
  // console.log('isLoading', isLoading, 'isDtiLoading', isDtiLoading, 'dtiHistoryEnabled', dtiHistoryEnabled);

  useEffect(() => {
    if (isOpen) {
      refetch();
      if (isDtiHosted) {
        refetchDti();
      }
    }
  }, [isOpen, refetch, listing, refetchDti]);

  const columns = useMemo(
    () => [
      {
        Header: 'Date & Time',
        accessor: 'changedAt' as keyof BarkerCoreModelsInventoryListingChange,
        Cell: ({ value }: CellProps<BarkerCoreModelsInventoryListingChange>) => dayjs(value).format('MM/DD/YYYY h:mma'),
      },
      {
        Header: 'Description',
        accessor: 'description' as keyof BarkerCoreModelsInventoryListingChange,
        Cell: ({ value }: CellProps<BarkerCoreModelsInventoryListingChange>) => (
          <span data-tooltip-id="tooltip-global" data-tooltip-content={value} data-tooltip-place="top">
            {value}
          </span>
        ),
      },
      {
        Header: 'User',
        accessor: 'changedBy' as keyof BarkerCoreModelsInventoryListingChange,
        Cell: ({ value }: CellProps<BarkerCoreModelsInventoryListingChange>) => (isGUID(value) ? getUserName(value) : value), // Barker only returns the userId (GUID) but DTI returns the email/username
      },
    ],
    [getUserName],
  );

  function EventTitle() {
    if (!listing) {
      return null;
    }
    return (
      <Group>
        <Group>
          <StadiumIcon color="var(--colors-iconFill)" size={32} />
          <Box>
            <Text size="sm" fw={600} className={classes.name}>
              {listing.event.name}
            </Text>
            <Text p={0} c="var(--colors-gray-5)" size="xs" fw={500} className={classes.descWrap}>
              <span>
                <span className={classes.desc}>{formatDate(listing.event.localDateTime, DateFormats.Extended)}</span> - {listing.event.venue.name} · {listing.event.venue.city},{' '}
                {listing.event.venue.state}
              </span>
            </Text>
          </Box>
        </Group>
      </Group>
    );
  }

  function ChangeTable({ changes }: { changes: BarkerCoreModelsInventoryListingChange[] }) {
    // const scrollBarSize = useMemo(() => scrollbarWidth(), []);

    const defaultSort = useMemo(() => [{ id: 'changedAt', desc: true }], []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
      {
        columns,
        data: changes ?? [],
        initialState: {
          sortBy: defaultSort,
        } as Partial<TableState<BarkerCoreModelsInventoryListingChange>>,
      },
      useSortBy,
    );

    function CustomNoRowsOverlay() {
      return (
        <Center h={230} pl={24} pr={24} pb={20} style={{ flexDirection: 'column', textAlign: 'center' }}>
          <Box
            bg="var(--colors-darkPaper)"
            h={44}
            w={44}
            mb={16}
            style={{
              border: '1px solid var(--colors-paper)',
              borderRadius: 44,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <HistoryIcon color="var(--colors-iconFill)" size={28} />
          </Box>
          <Text size="md" fw={600} c="var(--colors-gray-9)">
            No History Available
          </Text>
          <Text size="xs" c="var(--colors-gray-5)">
            Any changes made will appear here
          </Text>
        </Center>
      );
    }

    const SkeletonRow = () => (
      <div className={classes.tr}>
        <div className={classes.td}>
          <span>
            <Skeleton height={8} my={5.5} width="80%" />
          </span>
        </div>
        <div className={classes.td}>
          <span>
            <Skeleton height={8} my={5.5} width="80%" />
          </span>
        </div>
        <div className={classes.td}>
          <span>
            <Skeleton height={8} my={5.5} width="80%" />
          </span>
        </div>
      </div>
    );
    const SkeletonLoader = () => (
      <Box h={230} w="100%" style={{ overflow: 'auto' }}>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </Box>
    );

    const PriceRow = useCallback(
      ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const row = rows[index];
        prepareRow(row);
        const { key: rowKey, ...rowProps } = row.getRowProps({ style });
        return (
          <div key={rowKey} {...rowProps} className={classes.tr}>
            {row.cells.map((cell) => {
              const { key: cellKey, ...rest } = cell.getCellProps();
              return (
                <div {...rest} key={cellKey} className={classes.td}>
                  <span>{cell.render('Cell')}</span>
                </div>
              );
            })}
          </div>
        );
      },
      [prepareRow, rows],
    );

    return (
      <div {...getTableProps()} className={classes.table}>
        <div>
          {headerGroups.map((headerGroup) => (
            <div className={classes.tr} {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column) => (
                <div className={classes.th} {...column.getHeaderProps()} key={column.getHeaderProps().key}>
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>
        {(isLoading || (isDtiLoading && isDtiHosted)) && <SkeletonLoader />}
        {!(isLoading || (isDtiLoading && isDtiHosted)) && changes.length === 0 && <CustomNoRowsOverlay />}
        {!(isLoading || (isDtiLoading && isDtiHosted)) && changes.length > 0 && (
          <div {...getTableBodyProps()}>
            <FixedSizeList itemSize={36} height={230} itemCount={changes.length} width="100%" layout="vertical" overscanCount={100}>
              {PriceRow}
            </FixedSizeList>
          </div>
        )}
      </div>
    );
  }

  const parseDtiHistory = useCallback((value: string, changeType: string) => {
    const parts = value.split(' - ');
    const dateTime = dayjs(parts[0]).toDate();
    const user = parts[1];
    const message = parts.slice(2).join(' - ');
    return {
      changedAt: dateTime,
      changedBy: user,
      description: message,
      changeType,
      listingId: '0',
      listingChangeId: 0,
      tenantId: '',
    } satisfies BarkerCoreModelsInventoryListingChange;
  }, []);

  const dtiPriceHistory = useMemo(
    () =>
      dtiHistory?.data?.history
        ?.filter((h) => h.includes('Price changed') || h.includes('Changed the price') || h.includes('Listing price updated to'))
        .map((h) => parseDtiHistory(h, 'Price'))
        .filter((h) => h.changedBy !== 'brokernerdspricer' && h.changedBy !== 'ims@dtimanagement.com') ?? [],
    [parseDtiHistory, dtiHistory],
  );

  const dtiNotesHistory = useMemo(
    () => dtiHistory?.data?.history?.filter((h) => h.includes('External notes changed') || h.includes('Internal notes changed')).map((h) => parseDtiHistory(h, 'Notes')) ?? [],
    [parseDtiHistory, dtiHistory],
  );

  const dtiBroadcastHistory = useMemo(
    () =>
      dtiHistory?.data?.history
        ?.filter((h) => h.includes('shared to') || h.includes('Set hide seats') || h.includes('Set exchange sharing'))
        .map((h) => parseDtiHistory(h, 'IsBroadcasting')) ?? [],
    [parseDtiHistory, dtiHistory],
  );

  const dtiSplitsHistory = useMemo(
    () => dtiHistory?.data?.history?.filter((h) => h.includes('Set allowed splits')).map((h) => parseDtiHistory(h, 'Splits')) ?? [],
    [parseDtiHistory, dtiHistory],
  );

  const dtiOtherHistory = useMemo(
    () =>
      dtiHistory?.data?.history
        ?.filter(
          (h) =>
            !h.includes('Price changed') &&
            !h.includes('Changed the price') &&
            !h.includes('Listing price updated to') &&
            !h.includes('External notes changed') &&
            !h.includes('Internal notes changed') &&
            !h.includes('shared to') &&
            !h.includes('Set hide seats') &&
            !h.includes('Set exchange sharing') &&
            !h.includes('Set allowed splits'),
        )
        .map((h) => parseDtiHistory(h, 'Other'))
        .sort((a, b) => dayjs(a.changedAt).unix() - dayjs(b.changedAt).unix()) ?? [],
    [parseDtiHistory, dtiHistory],
  );

  const priceHistory = useMemo(
    () =>
      history?.data
        ?.filter((h) => h.changeType === 'Price')
        .concat(dtiPriceHistory)
        .sort((a, b) => dayjs(a.changedAt).unix() - dayjs(b.changedAt).unix()) ?? [],
    [history, dtiPriceHistory],
  );
  const broadcastHistory = useMemo(
    () =>
      history?.data
        ?.filter((h) => h.changeType === 'IsBroadcasting')
        .concat(dtiBroadcastHistory)
        .sort((a, b) => dayjs(a.changedAt).unix() - dayjs(b.changedAt).unix()) ?? [],
    [history, dtiBroadcastHistory],
  );
  const splitsHistory = useMemo(
    () =>
      history?.data
        ?.filter((h) => h.changeType === 'Splits')
        .concat(dtiSplitsHistory)
        .sort((a, b) => dayjs(a.changedAt).unix() - dayjs(b.changedAt).unix()) ?? [],
    [history, dtiSplitsHistory],
  );
  const notesHistory = useMemo(
    () =>
      history?.data
        ?.filter((h) => h.changeType === 'Notes')
        .concat(dtiNotesHistory)
        .sort((a, b) => dayjs(a.changedAt).unix() - dayjs(b.changedAt).unix()) ?? [],
    [history, dtiNotesHistory],
  );

  const autoPricerHistory = useMemo(
    () => history?.data?.filter((h) => h.changeType === 'Auto-Pricer').sort((a, b) => dayjs(a.changedAt).unix() - dayjs(b.changedAt).unix()) ?? [],
    [history],
  );

  const appName = isDtiHosted ? 'DTI Member Portal' : 'Broker Nerds Pricer';

  const hasAutoPricerHistoryFlag = useFlag('auto-pricer-history');
  const hasPriceHistoryGraphFlag = useFlag('price-history-graph');

  // const data = [
  //   { name: '2024-08-01', open: 109.75, close: 105.21, high: 109.75, low: 105.21 },
  // ];

  const data: any[] | undefined = [];

  if (hasPriceHistoryGraphFlag) {
    const cleanPriceHistory =
      history?.data
        ?.filter((h) => h.changeType === 'Price')
        .map((h) => ({ price: parseFloat(h.description.replace('Price changed to $', '')), changedAt: h.changedAt }))
        .sort((a, b) => dayjs(b.changedAt).unix() - dayjs(a.changedAt).unix()) ?? [];

    // Price history is sorted in descending order already
    const earliestPriceHistory = cleanPriceHistory.length > 0 ? cleanPriceHistory[cleanPriceHistory.length - 1].changedAt : null;
    const latestPriceHistory = cleanPriceHistory.length > 0 ? cleanPriceHistory[0].changedAt : null;

    // Create an array of all days between the earliest and latest price history
    const allDays = [];

    if (earliestPriceHistory && latestPriceHistory) {
      for (let day = earliestPriceHistory; day <= latestPriceHistory; day = dayjs(day).add(1, 'day').toDate()) {
        allDays.push(day);
      }
    }

    allDays.forEach((day) => {
      const dayPriceHistory = cleanPriceHistory.filter((h) => dayjs(h.changedAt).isSame(day, 'day'));
      const previousDayClose = data.length > 0 ? data[data.length - 1].openClose[1] : 0;
      const open = dayPriceHistory.length > 0 ? dayPriceHistory[dayPriceHistory.length - 1].price : previousDayClose;
      const close = dayPriceHistory.length > 0 ? dayPriceHistory[0].price : previousDayClose;
      const high = dayPriceHistory.length > 0 ? Math.max(...dayPriceHistory.map((h) => h.price)) : previousDayClose;
      const low = dayPriceHistory.length > 0 ? Math.min(...dayPriceHistory.map((h) => h.price)) : previousDayClose;
      data.push({ name: dayjs(day).format('YYYY-MM-DD'), openClose: [open, close], high, low });
    });

    console.log('priceHistory', JSON.stringify(cleanPriceHistory));
    console.log('candlesticks', JSON.stringify(data));
  }

  return (
    <Modal
      centered
      size="xl"
      opened={isOpen}
      closeButtonProps={{ size: 'md' }}
      onClose={() => setListing({ listing, isOpen: false })}
      title={<EventTitle />}
      classNames={{ header: classes.modalHeader, body: classes.modalBody }}
    >
      <Group mx="-1rem" px="md" py="sm" mb="sm" className={classes.gradient}>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Section
          </Text>
          <Text size="md">{listing?.section}</Text>
        </div>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Row
          </Text>
          <Text size="md">{listing?.row}</Text>
        </div>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Seats
          </Text>
          <Text size="md">{listing?.quantityRemaining ?? 0 > 1 ? `${listing?.seatFrom}-${listing?.seatThru}` : listing?.seatFrom}</Text>
        </div>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Qty
          </Text>
          <Text size="md">{listing?.quantityRemaining}</Text>
        </div>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Unit Cost
          </Text>
          <Text size="md">{formatCurrency(listing?.unitCost ?? 0)}</Text>
        </div>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Current Price
          </Text>
          <Group>
            <Text size="md">{formatCurrency(listing?.unitPrice ?? 0)}</Text>
            {listing?.pricerStatusId === 'AutoPriced' && <AutoPriceIcon size={20} />} {/* TODO: Replace this with the logic for auto pricing indicators to be developed later */}
          </Group>
        </div>
        {hasPriceHistoryGraphFlag && (
          <div>
            <Popover width={650} withinPortal>
              <Popover.Target>
                <ActionIcon>
                  <AnalyticsIcon size={24} />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Container className={`charts-wrapper ${classes.chartsWrapper}`} p={0} mt={0}>
                  <ResponsiveContainer width="100%" height="100%">
                    {/*<ComposedChart width={400} height={316} data={data} className={classes.composedChart}>*/}
                    {/*  <CartesianGrid strokeDasharray="0" stroke="var(--colors-gray-0" vertical={false} />*/}
                    {/*  <XAxis minTickGap={48} dataKey="name" strokeWidth={0} style={{ fontSize: 8, opacity: 0.65 }} height={16} />*/}
                    {/*  <YAxis*/}
                    {/*    tickLine={false}*/}
                    {/*    axisLine={false}*/}
                    {/*    dataKey="low"*/}
                    {/*    yAxisId="left"*/}
                    {/*    orientation="left"*/}
                    {/*    width={32}*/}
                    {/*    fontSize={8}*/}
                    {/*    style={{ fontSize: 8, opacity: 0.65 }}*/}
                    {/*    tickFormatter={(value) => {*/}
                    {/*      if (typeof value !== 'number') {*/}
                    {/*        return value;*/}
                    {/*      }*/}

                    {/*      return formatCurrency(value, false);*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*  <Line*/}
                    {/*    activeDot*/}
                    {/*    opacity={1}*/}
                    {/*    yAxisId="left"*/}
                    {/*    dot={false}*/}
                    {/*    type="monotone"*/}
                    {/*    dataKey="low"*/}
                    {/*    strokeWidth={2}*/}
                    {/*    stroke="var(--colors-brandcolor-5)"*/}
                    {/*  />*/}
                    {/*  /!*<Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: 'none' }} />*!/*/}
                    {/*</ComposedChart>*/}
                    <CustomShapeBarChart data={data} />
                  </ResponsiveContainer>
                </Container>
              </Popover.Dropdown>
            </Popover>
          </div>
        )}
      </Group>
      <Container p={0} mx="-1rem">
        <Tabs defaultValue="pricing">
          <Tabs.List pl="md" pr="md" className={classes.tabList}>
            <Tabs.Tab value="pricing">Pricing</Tabs.Tab>
            <Tabs.Tab value="broadcasting">Broadcasting</Tabs.Tab>
            <Tabs.Tab value="splits">Splits</Tabs.Tab>
            <Tabs.Tab value="notes">Notes</Tabs.Tab>
            {hasAutoPricerHistoryFlag && <Tabs.Tab value="auto-pricer">Auto-Pricer</Tabs.Tab>}
            {isDtiHosted && <Tabs.Tab value="other">Other</Tabs.Tab>}
          </Tabs.List>

          <Tabs.Panel value="pricing" pl="md" pr="md" pt="md" pb="md">
            <ChangeTable changes={priceHistory} />
          </Tabs.Panel>

          <Tabs.Panel value="broadcasting" pl="md" pr="md" pt="md" pb="md">
            <ChangeTable changes={broadcastHistory} />
          </Tabs.Panel>

          <Tabs.Panel value="splits" pl="md" pr="md" pt="md" pb="md">
            <ChangeTable changes={splitsHistory} />
          </Tabs.Panel>

          <Tabs.Panel value="notes" pl="md" pr="md" pt="md" pb="md">
            <ChangeTable changes={notesHistory} />
          </Tabs.Panel>

          {hasAutoPricerHistoryFlag && (
            <Tabs.Panel value="auto-pricer" pl="md" pr="md" pt="md" pb="md">
              <ChangeTable changes={autoPricerHistory} />
            </Tabs.Panel>
          )}

          {isDtiHosted && (
            <Tabs.Panel value="other" pl="md" pr="md" pt="md" pb="md">
              <ChangeTable changes={dtiOtherHistory} />
            </Tabs.Panel>
          )}
        </Tabs>
        {!isDtiHosted && (
          <Group p="md" px="md" bg="var(--colors-darkPaper)" wrap="nowrap" align="flex-start" gap={8}>
            <InfoIcon color="var(--colors-gray-5)" />
            <Text size="xs" c="var(--colors-gray-5)">
              The changes listed here only contain records of activity within the {appName}. Changes made from your point of sale or other external tools will not be accounted for.
            </Text>
          </Group>
        )}
      </Container>
    </Modal>
  );
}

const Candlestick = (props: { fill: any; x: any; y: any; width: any; height: any; low: any; high: any; openClose: [any, any] }) => {
  const {
    fill,
    x,
    y,
    width,
    height,
    low,
    high,
    openClose: [open, close],
  } = props;
  const isGrowing = open < close;
  const isFlat = open === close;
  const color = isFlat ? 'var(--colors-paperReverse)' : isGrowing ? 'green' : 'red';
  const ratio = Math.abs(height / (open - close));
  return (
    <g stroke={color} fill="none" strokeWidth="2">
      <path
        d={`
          M ${x},${y}
          L ${x},${y + height}
          L ${x + width},${y + height}
          L ${x + width},${y}
          L ${x},${y}
        `}
      />
      {/* bottom line */}
      {isGrowing ? (
        <path
          d={`
            M ${x + width / 2}, ${y + height}
            v ${(open - low) * ratio}
          `}
        />
      ) : (
        <path
          d={`
            M ${x + width / 2}, ${y}
            v ${(close - low) * ratio}
          `}
        />
      )}
      {/* top line */}
      {isGrowing ? (
        <path
          d={`
            M ${x + width / 2}, ${y}
            v ${(close - high) * ratio}
          `}
        />
      ) : (
        <path
          d={`
            M ${x + width / 2}, ${y + height}
            v ${(open - high) * ratio}
          `}
        />
      )}
    </g>
  );
};

const colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];

function CustomShapeBarChart({ data }: { data: CandlestickData[] }) {
  return (
    <BarChart width={600} height={300} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
      <XAxis dataKey="ts" />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Bar
        dataKey="openClose"
        fill="#8884d8"
        // @ts-ignore
        shape={<Candlestick />}
        // label={{ position: 'top' }}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
        ))}
      </Bar>
    </BarChart>
  );
}

type CandlestickData = {
  openClose: [number, number];
  high: number;
  low: number;
};
