import { useCallback, useEffect, useMemo, useState } from 'react';
import { queryClient, setUserRoleInterceptor, updateTenantId } from './api-config';
import {
  BarkerCoreModelsAdministrativeUserDetails,
  getApiTenantsTenantIdUsers,
  getGetApiPrincipalsFeaturesQueryKey,
  getGetApiTenantsTenantIdUsersQueryKey,
  useGetApiPrincipalsFeatures,
  useGetApiPrincipalsSelf,
  useGetApiTenants,
  useGetDtiAccounts,
  usePutApiPrincipalsFeaturesFeatureIdSeen,
} from '../api';
import { useAtomsDevtools } from 'jotai-devtools';
import { useAtomValue, useSetAtom } from 'jotai';
import yasml from '@thirtytech/yasml';
import { useQueries } from '@tanstack/react-query';
import { useDidUpdate, useLocalStorage } from '@mantine/hooks';
import { AxiosError } from 'axios';
import { useTokenRefresh } from '../components/hooks/useTokenRefresh';
import { auth } from './atoms.auth';
import { useLocation } from 'react-router-dom';
import { useMemoDeep } from '../utils/use-memo-weak';
import { isDtiHosted } from '../utils/whitelabel-consts';
import { PricerView } from '../types';

export const AUTH_ERRORS = [401, 403];

const GlobalState = () => {
  if (import.meta.env.VITE_JOTAI_DEVTOOLS) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAtomsDevtools('BrokerNerds');
  }
  const location = useLocation();
  const isMobile = location.pathname.startsWith('/mobile');
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const setPrincipal = useSetAtom(auth.principalAtom);
  const [isAnimationEnabled, setIsAnimationEnabled] = useState(false);
  const { handleAccessTokenSilently } = useTokenRefresh();
  const [storedSelectedTenants, setStoredSelectedTenants] = useLocalStorage<string[]>({
    key: 'selected-tenants',
    defaultValue: [],
    getInitialValueInEffect: false,
  });
  const [selectedTenantIds, _setSelectedTenantIds] = useState<string[]>(storedSelectedTenants);
  const setSelectedTenantIds = useCallback((tenantIds: string[]) => {
    updateTenantId(tenantIds);
    _setSelectedTenantIds(tenantIds);
  }, []);

  useEffect(() => {
    updateTenantId(selectedTenantIds);
  }, [selectedTenantIds]);

  const onQueryClientError = useCallback(
    (error: unknown) => {
      const axiosError = error as AxiosError;

      if (AUTH_ERRORS.includes(axiosError.response?.status || 0)) {
        handleAccessTokenSilently();
      }
    },
    [handleAccessTokenSilently],
  );

  queryClient.setQueryDefaults([], {
    onError: onQueryClientError,
  });

  queryClient.setMutationDefaults([], {
    onError: onQueryClientError,
  });

  const { data: principal } = useGetApiPrincipalsSelf({
    query: {
      enabled: !!apiToken,
      select(data) {
        return data.data;
      },
    },
  });

  const { data: unseenHints } = useGetApiPrincipalsFeatures({
    query: {
      enabled: !!apiToken,
      select(data) {
        return data.data;
      },
    },
  });

  const { mutateAsync: markFeatureSeenMutation } = usePutApiPrincipalsFeaturesFeatureIdSeen();

  const markFeatureSeen = useCallback(
    async (featureName: string) => {
      const featureId = unseenHints?.find((x) => x.name === featureName)?.featureId;
      if (!featureId) {
        return;
      }

      queryClient.setQueryData(getGetApiPrincipalsFeaturesQueryKey(), (data: any) => ({
        ...data,
        data: data.data.filter((x: any) => x.featureId !== featureId),
      }));
      await markFeatureSeenMutation({ featureId });
    },
    [markFeatureSeenMutation, unseenHints],
  );

  const needsFeatureHint = useCallback((featureName: string) => unseenHints?.some((x) => x.name === featureName), [unseenHints]);

  useDidUpdate(() => {
    setPrincipal(principal);
  }, [principal]);

  const { data: tenants, isLoading: isTenantsLoading } = useGetApiTenants({
    query: {
      enabled: !!apiToken,
    },
  });

  useEffect(() => {
    if (tenants?.data !== undefined && tenants?.data.length > 0 && selectedTenantIds.length === 0) {
      if (storedSelectedTenants?.length > 0 && storedSelectedTenants.every((x) => tenants?.data.map((y) => y.tenantId).includes(x))) {
        setSelectedTenantIds(storedSelectedTenants);
      } else if (tenants?.data.some((t) => t.name === 'Demo Company')) {
        const tenantIds = tenants?.data.filter((t) => t.name === 'Demo Company').map((t) => t.tenantId);
        setSelectedTenantIds(tenantIds);
        setStoredSelectedTenants(tenantIds);
      } else {
        const tenantIds = tenants?.data.length === 2 ? tenants?.data.map((t) => t.tenantId) : [tenants?.data[0].tenantId];
        setSelectedTenantIds(tenantIds);
        setStoredSelectedTenants(tenantIds);
      }
    }
  }, [selectedTenantIds.length, setSelectedTenantIds, setStoredSelectedTenants, storedSelectedTenants, tenants?.data]);

  const setTenants = useSetAtom(auth.tenantsAtom);

  useEffect(() => {
    if (tenants?.data) {
      setTenants(tenants?.data);
    }
  }, [setTenants, tenants?.data]);

  const _allUsers = useQueries({
    queries:
      selectedTenantIds?.map((tenantId) => ({
        queryKey: getGetApiTenantsTenantIdUsersQueryKey(tenantId),
        enabled: !!apiToken && selectedTenantIds.length > 0,
        queryFn: () => getApiTenantsTenantIdUsers(tenantId),
      })) ?? [],
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allUsers = useMemo(() => _allUsers, [..._allUsers.map((x) => x.isLoading)]);

  const users = useMemoDeep(
    () =>
      allUsers?.reduce((acc, curr) => {
        if (curr.data?.data) {
          acc.push(...curr.data.data);
        }
        return acc;
      }, [] as BarkerCoreModelsAdministrativeUserDetails[]),
    [allUsers],
  );

  const currentUsers = useMemoDeep(() => users?.filter((u) => u.principalId === principal?.principalId), [principal?.principalId, users]);
  const currentUser = useMemoDeep(() => {
    const user = users?.find((u) => u.principalId === principal?.principalId);
    setUserRoleInterceptor(user?.roleId || 'None');
    return user;
  }, [principal?.principalId, users]);

  const getUserName = useCallback(
    (id: string | null | undefined) => {
      const user = users?.find((x) => x.principalId === id);
      return currentUser?.principalId === id ? 'you' : user?.email.toLowerCase() || 'unknown user';
    },
    [currentUser?.principalId, users],
  );

  const isDtiOpu = useMemoDeep(
    () => (tenants?.data?.length ?? 0) >= 1 && tenants?.data?.every((t) => t.pointOfSaleId === 'DtiPortal' && t.name.includes('OPU')) === true,
    [tenants],
  );

  const { data: _accounts } = useGetDtiAccounts({
    query: {
      enabled: isDtiHosted && !!apiToken,
      select(data) {
        return data.data;
      },
    },
  });

  const [pricerView, setPricerView] = useLocalStorage({
    key: 'setting-pricerView',
    defaultValue: PricerView.Standard,
    getInitialValueInEffect: false,
  });

  const pricerMode = useMemo(() => principal?.settings?.pricerSettings?.pricingMode, [principal?.settings?.pricerSettings?.pricingMode]);

  const dtiAccounts = useMemo(() => _accounts?.accounts ?? [], [_accounts]);

  // Used for testing the authentication logic in the app.
  // const { data: httperror } = useQuery(['test-http'], () => axios.get('https://my.api.mockaroo.com/httpstatus?key=03145b80'), {
  //   refetchOnWindowFocus: true
  // })

  return {
    isTenantsLoading,
    currentUser,
    currentUsers,
    setStoredSelectedTenants,
    tenants: tenants?.data,
    principal,
    updateTenantId,
    selectedTenantIds,
    setSelectedTenantIds,
    users,
    getUserName,
    isAnimationEnabled,
    setIsAnimationEnabled,
    isMobile,
    isDtiOpu,
    dtiAccounts,
    needsFeatureHint,
    markFeatureSeen,
    pricerMode,
    pricerView,
    setPricerView,
  };
};

export const { Provider: GlobalStateProvider, useSelector: useGlobalState } = yasml(GlobalState);
