import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GetSeatingChartResponse } from '../../../types';
import './style.css';
import { formatMarketplaceSection } from '../../../utils/formatters';
import { Tooltip } from '@mantine/core';
import { BarkerCoreEnumsMarketplace, BarkerCoreModelsMarketplacesSeatingChartZone } from '../../../api';
import { useHoverKeystrokes } from '../../hooks/useHoverKeystrokes';
import { checkDistance, findCenterOfUPath, matchSectionNumbers } from '../../../utils/svg-utils';
import { useMouse } from '@mantine/hooks';
import { useGlobalState } from '../../../data/GlobalState';
import fastdom from 'fastdom';
import { TenantIdListingId } from '../../../models/tenantIdListingId';

const SECTION_BLACKLIST = ['stage', 'stqge'];

export type SvgSeatingChartProps = {
  tenantListingId: TenantIdListingId | undefined;
  selectedListingSection: string | null | undefined;
  selectedSections: string[] | null;
  onSectionClick: (sectionIds: string[]) => void;
  sectionsWithListings: string[] | null;
  suggestedSections: string[] | null;
  zoneMode: boolean;
  marketplaceId: BarkerCoreEnumsMarketplace;
  marketSectionAliases: string[] | undefined;
  markAllSections?: boolean;
  updateAllSectionsInSeatingChart: (sections: string[]) => void;
} & Partial<GetSeatingChartResponse>;

export function SvgSeatingChart({
  tenantListingId,
  selectedListingSection,
  selectedSections,
  sectionsWithListings,
  suggestedSections,
  onSectionClick,
  zones,
  svgUrl,
  zoneMode,
  marketplaceId,
  updateAllSectionsInSeatingChart,
  marketSectionAliases,
  markAllSections,
}: SvgSeatingChartProps) {
  const svgRef = useRef<HTMLDivElement>(null);
  const [tooltipLabel, setTooltipLabel] = useState<string>('');
  const [previousSvgUrl, setPreviousSvgUrl] = useState<string>();
  const [svgContent, setSvgContent] = useState<string>();
  const clickDragging = useRef(false);
  const dragSelectedSectionsRef = useRef<string[]>([]);
  const [dragSelectedSections, setDragSelectedSections] = useState<string[]>([]);
  const toggleDragRemovableRef = useRef(false);
  const selectedSectionsRef = useRef(selectedSections);
  const { isKeyPressedRef } = useHoverKeystrokes('Shift');
  const lastMousePositionRef = useRef<{ x: number; y: number }>();
  const { x: lastMouseX, y: lastMouseY } = useMouse();
  const zoneModeRef = useRef(zoneMode);
  let dragging = useRef(false);
  let timer = useRef<NodeJS.Timeout>();
  const pathId = useMemo(() => {
    switch (marketplaceId) {
      case BarkerCoreEnumsMarketplace.Ticketmaster:
        return 'data-section-names';
      case BarkerCoreEnumsMarketplace.TicketEvolution:
        return 'data-section-id';
      default:
        return 'id';
    }
  }, [marketplaceId]);

  useEffect(() => {
    selectedSectionsRef.current = selectedSections;
    zoneModeRef.current = zoneMode;
  }, [selectedSections, zoneMode]);

  useEffect(() => {
    const trackMouse = (e: MouseEvent) => {
      lastMousePositionRef.current = { x: e.clientX, y: e.clientY };
    };
    document.addEventListener('mousemove', trackMouse);
    return () => {
      document.removeEventListener('mousemove', trackMouse);
    };
  }, []);

  useEffect(() => {
    fastdom.mutate(() => {
      svgRef.current
        ?.querySelector('svg')
        ?.querySelectorAll('circle')
        .forEach((x) => x.remove());
    });
  }, [selectedListingSection]);

  useEffect(() => {
    if (svgUrl && svgUrl !== previousSvgUrl) {
      setPreviousSvgUrl(svgUrl);
      axios
        .get(svgUrl)
        .catch((error) => {})
        .then((response) => {
          const svgRaw = response?.data.substr(response.data.indexOf('<svg'));
          setSvgContent(svgRaw);
        });
    }
  }, [svgUrl]);

  const onMouseout = useCallback((paths: SVGElement[], sectionHoverWrapper: Element) => {
    return function handleMouseout(event: MouseEvent) {
      paths.forEach((p) => {
        p.classList.remove('zone-hover');
        p.classList.remove('section-hover');
        p.classList.remove('active-section-hover');
        sectionHoverWrapper?.classList.add('hidden');
        setTooltipLabel('');
      });
    };
  }, []);

  const onMousedown = useCallback((event: MouseEvent) => {
    clickDragging.current = true;
    timer.current = setTimeout(() => (dragging.current = true), 200);
    const target = event.target as SVGElement;
    const section = target.getAttribute(pathId)?.replaceAll('-', ' ');
    if (isKeyPressedRef.current && section) {
      if (selectedSectionsRef.current?.includes(section)) {
        toggleDragRemovableRef.current = true;
      } else {
        toggleDragRemovableRef.current = false;
      }
      dragSelectedSectionsRef.current.push(section);
      setDragSelectedSections([...dragSelectedSectionsRef.current]);
    }
  }, []);

  const onMouseup = useCallback(() => {
    clickDragging.current = false;
    clearTimeout(timer.current);
    setTimeout(() => (dragging.current = false));
    if (dragSelectedSectionsRef.current.length > 0) {
      onSectionClick(Array.from(new Set(structuredClone(dragSelectedSectionsRef.current))));
      dragSelectedSectionsRef.current = [];
      setDragSelectedSections([]);
    }
  }, []);

  const onPathClick = useCallback((zone: BarkerCoreModelsMarketplacesSeatingChartZone, section: string) => {
    return function handleClick(event: MouseEvent) {
      if (dragging.current) {
        event.stopPropagation();
        return;
      }

      if (zoneModeRef.current && zone) {
        onSectionClick(zone.sections.map((x) => x.replaceAll('-', ' ')));
      } else {
        onSectionClick(section.replaceAll('-', ' ').split(','));
      }
    };
  }, []);

  const onSvgClick = useCallback((event: MouseEvent) => {
    const svg = svgRef.current!.querySelector('svg')!;
    svg.focus();
  }, []);

  const onMouseover = useCallback(
    (
      paths: SVGElement[],
      path: SVGElement,
      sectionHoverWrapper: Element,
      sectionHoverText: Element,
      section: string,
      zone: BarkerCoreModelsMarketplacesSeatingChartZone | undefined,
    ) => {
      return function handleMouseover(event: MouseEvent) {
        const target = event.target as SVGElement;
        if (clickDragging.current && isKeyPressedRef.current) {
          dragSelectedSectionsRef.current.push(section);
          setDragSelectedSections([...dragSelectedSectionsRef.current]);
        }

        if (sectionHoverWrapper && sectionHoverText) {
          sectionHoverText.innerHTML = '';
          if (marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster) {
            const sectionIdLabel = path.getAttribute('data-section-id')!;
            setTooltipLabel(formatMarketplaceSection(sectionIdLabel));
          } else {
            setTooltipLabel(formatMarketplaceSection(section));
          }
        }
        paths.forEach((p) => {
          const section = p.getAttribute(pathId)!;
          if (zone?.sections.some((x) => section.split(',').includes(x))) {
            p.classList.add('zone-hover');
          }
          if (section === target.getAttribute(pathId)!) {
            p.classList.add('section-hover');
            if (selectedSectionsRef.current?.includes(section!)) {
              p.classList.add('active-section-hover');
            }
          }
        });
      };
    },
    [],
  );

  const svgMouseover = useCallback(() => {
    requestAnimationFrame(() => {
      svgRef.current?.classList.remove('suggest-section-animation');
    });
  }, []);
  const svgMouseout = useCallback(() => {
    svgRef.current?.classList.add('suggest-section-animation');
  }, []);

  useEffect(() => {
    // Handles wiring up the event handlers and disposing of them only
    const pathOnMouseOut = new Map<SVGElement, (event: MouseEvent) => void>();
    const pathOnClick = new Map<SVGElement, (event: MouseEvent) => void>();
    const pathOnMouseOver = new Map<SVGElement, (event: MouseEvent) => void>();

    if (svgContent) {
      svgRef.current!.innerHTML = svgContent;
      const sectionHoverWrapper = document.querySelector('.sections-hover-wrapper');
      const sectionHoverText = document.querySelector('.sections-hover-text');
      const svg = svgRef.current!.querySelector('svg')!;
      const venueWrapper = document.getElementById('venueWrapper')!;
      svg.addEventListener('mouseenter', svgMouseover);
      venueWrapper.addEventListener('mouseenter', svgMouseover);
      venueWrapper.addEventListener('mouseleave', svgMouseout);
      let paths = [...svg.querySelectorAll(`[${pathId}]`)] as SVGElement[];
      const text = svg.querySelectorAll('text');
      const tspan = svg.querySelectorAll('tspan');

      if (marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster) {
        const basePaths = ([...svg.getElementsByTagName('path')] as SVGElement[]).filter((p) => p.parentElement?.tagName === 'svg');
        basePaths.forEach((p) => {
          p.style.pointerEvents = 'none';
        });
      }

      // Note: This is mostly for the tevo svg (also the Ticketmaster one). Since the numbers are ontop of the text
      text.forEach((t) => {
        if (t) {
          t.style.pointerEvents = 'none';

          // This is special logic for Ticketmaster so we don't change the color of base-level text
          if (t.parentElement?.classList.contains('label')) {
            t.setAttribute('fill', 'var(--colors-paperReverse)');
          }
        }
      }); // Needed to make the text not block the click events and use underlying section paths

      // Note: This is for the Ticketmaster SVG so the labels line up correctly to their x/y coordinates
      tspan.forEach((t) => {
        if (t && t.parentElement?.classList.contains('label')) {
          t.style.textAnchor = 'middle';
        }
      });

      const sections = paths
        .map((path) => {
          const section = path.getAttribute(pathId)!.replaceAll('-', ' ');
          if (SECTION_BLACKLIST.includes(section.toLowerCase())) {
            return;
          }

          const zone = zones?.find((x) => x.sections.some((x) => section!.replaceAll(' ', '-').split(',').includes(x)));

          pathOnMouseOver.set(path, onMouseover(paths, path, sectionHoverWrapper!, sectionHoverText!, section, zone));
          path.addEventListener('mouseover', pathOnMouseOver.get(path)!);

          pathOnMouseOut.set(path, onMouseout(paths, sectionHoverWrapper!));
          path.addEventListener('mouseout', pathOnMouseOut.get(path)!);

          pathOnClick.set(path, onPathClick(zone!, section));
          path.addEventListener('click', pathOnClick.get(path)!);

          path.addEventListener('mousedown', onMousedown);
          path.addEventListener('mouseup', onMouseup);

          svg.addEventListener('click', onSvgClick);

          return section;
        })
        .filter((x) => x)
        .flatMap((x) => {
          if (marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster) {
            return x?.split(',') ?? [];
          }
          return x;
        }) as string[];

      updateAllSectionsInSeatingChart(sections);

      svg.setAttribute('tabindex', '0');
      svg.setAttribute('width', '450');
      svg.setAttribute('height', '450');
    }

    return () => {
      const svg = svgRef.current?.querySelector('svg')!;
      if (svg) {
        const venueWrapper = document.getElementById('venueWrapper')!;
        // svgMouseover(); // Not sure I want to do this yet.
        svg.removeEventListener('mouseenter', svgMouseover);
        venueWrapper.removeEventListener('mouseenter', svgMouseover);
        venueWrapper.removeEventListener('mouseleave', svgMouseout);
        const paths = [...svg.querySelectorAll(`[${pathId}]`)] as SVGElement[];

        if (marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster) {
          const basePaths = ([...svg.getElementsByTagName('path')] as SVGElement[]).filter((p) => p.parentElement?.tagName === 'svg');
          basePaths.forEach((p) => {
            fastdom.mutate(() => {
              p.style.pointerEvents = 'none';
            });
          });
        }

        paths.forEach((path) => {
          path.removeEventListener('mouseover', pathOnMouseOver.get(path)!);
          path.removeEventListener('mouseout', pathOnMouseOut.get(path)!);
          path.removeEventListener('click', pathOnClick.get(path)!);
          path.removeEventListener('mousedown', onMousedown);
          path.removeEventListener('mouseup', onMouseup);
          svg.removeEventListener('click', onSvgClick);
          svg.removeEventListener('mousemove', checkDistance);
        });
      }
    };
  }, [svgContent]);

  useEffect(() => {
    if (svgContent) {
      const svg = svgRef.current!.querySelector('svg')!;
      let paths = [...svg.querySelectorAll(`[${pathId}]`)] as SVGElement[];

      if (marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster) {
        const basePaths = paths.filter((p) => p.parentElement?.tagName === 'svg');
        basePaths.forEach((p) => {
          p.style.pointerEvents = 'none';
        });

        paths = paths.filter((p) => p.parentElement?.id === 'polygons');
      } // For Ticketmaster, ignore any paths that aren't part of the polygons group, since they are part of the underlying image and not the overlay

      const allSections = paths.map((path) => path.getAttribute(pathId)?.replaceAll('-', ' ')).filter((x) => x) as string[];

      let found: string[] = [];
      try {
        if (selectedListingSection) {
          const regex = new RegExp(`(?<!\\d)${selectedListingSection}(?!\\d)`);
          found = allSections.filter((x) => regex.test(x));
        }
      } catch (error) {
        if (selectedListingSection) {
          found = allSections.filter((x) => matchSectionNumbers(x, selectedListingSection));
        }
      }

      paths.forEach((path) => {
        const section = path.getAttribute(pathId)?.replaceAll('-', ' ');
        const sections = path.getAttribute(pathId)?.replaceAll('-', ' ').split(',') ?? [];
        if (SECTION_BLACKLIST.includes(section?.toLowerCase()!)) {
          return;
        }

        if (
          sections.includes(selectedListingSection?.toLowerCase().replaceAll('-', ' ')!) ||
          (found.length == 1 && found[0] === section) ||
          (Array.isArray(marketSectionAliases) && marketSectionAliases.some((x) => x.toLowerCase().replaceAll('-', ' ') === section?.toLowerCase().replaceAll('-', ' ')!))
        ) {
          // const centerPosition = findPathCenter(path);
          const centerPosition = findCenterOfUPath(path);
          // const centerPosition = findApproximateCenterOfUPath(path);
          // const centerPosition = findCenterXCenterYPath(path);
          // const centerPosition = findCenterPointOnPath(path);
          // const centerPosition = findBottomCenterPath(path);
          // const centerPosition = getCenterOfSvgPathWithTransform(getDomPathToSVGElement(path));
          // const centerPosition = findRectangleCenters(path.getAttribute('d')!);
          if (centerPosition) {
            const countCenters: { x: number; y: number }[] = []; //findRectangleCenters(getUniqueSelector(path));
            if (countCenters.length > 2 && !countCenters.some((x) => isNaN(x.x) || isNaN(x.y))) {
              countCenters.map((centerPosition) => {
                const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
                circle.setAttribute('cx', centerPosition.x.toString());
                circle.setAttribute('cy', centerPosition.y.toString());
                circle.setAttribute('r', marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster ? `${200}px` : `${10}px`); // radius of the circle
                circle.setAttribute('fill', 'var(--colors-red-error)');
                circle.setAttribute('pointer-events', 'none');
                fastdom.mutate(() => {
                  svgRef.current?.querySelector('svg')?.appendChild(circle);
                });
              });
            } else {
              const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
              circle.setAttribute('cx', centerPosition.x.toString());
              circle.setAttribute('cy', centerPosition.y.toString());
              circle.setAttribute('r', marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster ? `${100}px` : `${10}px`); // radius of the circle
              circle.setAttribute('fill', 'var(--colors-red-error)');
              circle.setAttribute('pointer-events', 'none');
              fastdom.mutate(() => {
                svgRef.current?.querySelector('svg')?.appendChild(circle);
              });
            }
            svg.addEventListener('mousemove', checkDistance);
            checkDistance(
              new MouseEvent('mousemove', {
                view: window,
                bubbles: true,
                cancelable: true,
                clientX: lastMouseX,
                clientY: lastMouseY,
              }),
            );
          }
        }

        // Clean slate
        const classes = new Set<string>();

        classes.add('section');
        if (section) {
          const splitSections = section.split(',').map((x) => x.toLowerCase());
          if (markAllSections || sectionsWithListings?.some((x) => splitSections.includes(x))) {
            classes.add('has-listings');
          }
          if (suggestedSections?.some((x) => splitSections.includes(x.toLowerCase())) && (selectedSections?.length ?? 0) === 0) {
            classes.add('suggest-section');
          }
          if (toggleDragRemovableRef.current) {
            if (selectedSections?.includes(section) && !dragSelectedSectionsRef.current.includes(section)) {
              classes.add('active-section');
            }
          } else if (selectedSections?.includes(section) || dragSelectedSectionsRef.current.includes(section)) {
            classes.add('active-section');
          }
          if (marketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster) {
            // const altReserveKey = path.getAttribute('data-alt-reserve-key')!;
            const sectionNames = section.split(',').map((x) => x.replaceAll('-', ' ')) || [];
            if (sectionNames.every((x) => selectedSections?.includes(x))) {
              classes.add('active-section');
            }
          }
        }
        fastdom.mutate(() => {
          path.classList.add(...classes.keys());
          path.classList.forEach((c) => {
            if (!Array.from(classes).includes(c) && c !== 'zone-hover') {
              path.classList.remove(c);
            }
          });
        });
      });
    }
  }, [svgContent, selectedSections, sectionsWithListings, suggestedSections, zoneMode, dragSelectedSections, marketSectionAliases]);

  useEffect(() => {
    requestAnimationFrame(() => {
      svgRef.current?.classList.remove('suggest-section-animation');
    });
  }, [tenantListingId]);

  const { isMobile } = useGlobalState();
  return (
    <Tooltip.Floating label={tooltipLabel} fz="xs" style={{ textTransform: 'capitalize' }} disabled={tooltipLabel === '' || isMobile} bg={'var(--colors-gray-7)'}>
      <div
        onMouseLeave={() => {
          if (dragSelectedSectionsRef.current.length > 0) {
            onMouseup();
          }
        }}
        className={`${zoneMode ? 'bnSeatingChart zoneSelect' : 'bnSeatingChart'} ${(suggestedSections?.length ?? 0) > 0 ? 'suggest-section-animation' : ''}`}
        ref={svgRef}
      />
    </Tooltip.Floating>
  );
}
